exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bavagli-in-tnt-tsx": () => import("./../../../src/pages/bavagli-in-tnt.tsx" /* webpackChunkName: "component---src-pages-bavagli-in-tnt-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personalizza-prodotti-tsx": () => import("./../../../src/pages/personalizza-prodotti.tsx" /* webpackChunkName: "component---src-pages-personalizza-prodotti-tsx" */),
  "component---src-pages-richiedi-campione-gratuito-tsx": () => import("./../../../src/pages/richiedi-campione-gratuito.tsx" /* webpackChunkName: "component---src-pages-richiedi-campione-gratuito-tsx" */),
  "component---src-pages-salviette-monouso-tsx": () => import("./../../../src/pages/salviette-monouso.tsx" /* webpackChunkName: "component---src-pages-salviette-monouso-tsx" */)
}

